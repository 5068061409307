<template>
  <div class="form-group" :class="{ error }">
    <div v-if="label" class="form-group__label">
      <label :for="id">
        {{ label }}

        <span v-if="errorInLabel">
          {{ formatError }}
        </span>
      </label>

      <router-link
        v-if="labelLink"
        :to="labelLink.link"
        tabindex="-1"
      >
        {{ labelLink.title }}
      </router-link>
    </div>
    <input
      v-if="type === 'input' && !mask"
      v-model="model"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      :type="inputType"
      :minlength="minLength"
      :disabled="disabled"
      :autofocus="autofocus"
      ref="inputArea"
    />

    <the-mask
      v-if="mask"
      v-model="model"
      :id="id"
      :masked="true"
      :mask="mask"
      :tokens="tokens"
      :placeholder="placeholder"
      :required="required"
      :type="inputType"
      :minlength="minLength"
      :disabled="disabled"
      ref="inputArea"
    />

    <textarea
      v-if="type === 'textarea'"
      v-model="model"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      :minlength="minLength"
      :disabled="disabled"
      ref="inputArea"
    ></textarea>

    <SearchableSelect
      v-if="type === 'searchableSelect'"
      :id="id"
      :options="options"
      :model="model"
      :desc="optionDesc"
      :value="selectValue"
      :searchKeys="searchKeys"
      @change="change"
    />

    <select
      v-if="type === 'select'"
      v-model="model"
      :id="id"
      :required="required"
      :disabled="disabled"
    >
      <option
        v-for="(option, index) of options"
        :key="index"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>
    <div v-if="error && !errorInLabel" class="error">{{ formatError }}</div>
  </div>
</template>

<script>
import SearchableSelect from '@/components/ui/SearchableSelect';

export default {
  name: 'FormGroup',
  components: {
    SearchableSelect,
  },

  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    // здесь специально нет default, потому что он должен быть `undefined`
    tokens: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: null,
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'input',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    value: null,
    options: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    labelLink: {
      type: Object,
      default: null,
    },
    minLength: {
      type: Number,
      default: null,
    },
    errorInLabel: {
      type: Boolean,
      default: false,
    },
    searchKeys: {
      type: Array,
      default: null,
    },
    optionDesc: {
      type: String,
      default: '',
    },
    selectValue: {
      type: String,
      default: null,
    },
  },
  computed: {
    id() {
      return `form-group-el-${this._uid}`;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.change(value);
      },
    },
    formatError() {
      return Array.isArray(this.error)
        ? this.error.join('\n')
        : this.error;
    }
  },

  mounted() {
    if (this.autofocus) {
      if (this.$refs.inputArea.focus) {
        this.$refs.inputArea.focus();
      } else {
        this.$nextTick(() => {
          this.$refs.inputArea.$el.focus();
        });
      }
    }
  },

  methods: {
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
.dark-theme {
  .form-group {
    label {
      color: $darkThemeText;
    }
  }
}

.form-group {
  margin: 12px 0;

  &.error {
    input, textarea {
      border-color: $red;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: flex;
    justify-content: space-between;
    font-size: rem(12px);
    font-weight: 500;
    width: 100%;

    span {
      white-space: pre;
      color: red;
      font-size: rem(11px);
      margin-left: 5px;
      font-weight: 400;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;

    label {
      width: auto;
    }

    a {
      font-size: rem(12px);
      color: $blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .error {
    white-space: pre;
    color: $red;
    font-size: rem(11px);
    margin-top: 4px;
  }
}
</style>
